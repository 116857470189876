import { useState } from 'react';
import { Link } from "react-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faCaretDown, faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/images/logo.png';
import { useTranslation } from "react-i18next";
import { HelmetProvider, Helmet } from 'react-helmet-async';

function Header() {
	const [navbarOpen, setNavbarOpen] = useState(false);

	const handleToggle = () => {
		setNavbarOpen(!navbarOpen);
	}

	const { t, i18n } = useTranslation();

	const changeLanguage = (event) => {
		i18n.changeLanguage(event.target.value);
	};

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>{t("site.name")}</title>
					<meta name="description" content={t("site.description")} />
				</Helmet>
			</HelmetProvider>

			<header className="header">
				<nav className="navbar--hamburger">
					<button className="navbar__button" onClick={handleToggle}><FontAwesomeIcon icon={faBars} /></button>

					<div className="navbar__logo">
						<Link smooth to="root" className="navbar__link--logo"><img className="navbar__logo__image" src={logo} alt="" /></Link>
					</div>

					<ul className={`navbar__list${navbarOpen ? " navbar__list--show" : ""}`}>
						<li className="navbar__item"><Link smooth spy to="homepage" className="navbar__link" activeClass="navbar__link--active">{t("header.home")}</Link></li>
						<li className="navbar__item"><Link smooth spy to="prices" className="navbar__link" activeClass="navbar__link--active">{t("header.prices")}</Link></li>
						{<li className="navbar__item"><Link smooth spy to="gallery" className="navbar__link" activeClass="navbar__link--active">{t("header.gallery")}</Link></li>}
						<li className="navbar__item"><Link smooth spy to="contact" className="navbar__link" activeClass="navbar__link--active">{t("header.contact")}</Link></li>

						<ul className="navbar__socials">
							<li className="navbar__socials__item">
								<a className="navbar__socials__link navbar__socials__link--instagram" target="_blank" rel="noreferrer" href="https://www.instagram.com/toilettagecoconutetcie/">
									<i className="fa-brands fa-instagram"></i>
									<FontAwesomeIcon icon={faInstagram} />
								</a>
							</li>

							<li className="navbar__socials__item">
								<a className="navbar__socials__link navbar__socials__link--facebook" target="_blank" rel="noreferrer" href="https://www.facebook.com/Toilettage-Coconutcie-101100969493178">
									<FontAwesomeIcon icon={faFacebookF} />
								</a>
							</li>

							<div className="lang-select__wrapper">
								<div className="lang-select lang-select__dropdown">
									<div className="lang-select__button">
										{t("lang.select")} <FontAwesomeIcon icon={faCaretDown} />
									</div>

									<div className="lang-select__dropdown dropdown__content">
										<label>
											{t("lang.fr")}
											<input type="radio" value="fr" name="language" onChange={changeLanguage} />
										</label>

										<label>
											{t("lang.en")}
											<input type="radio" value="en" name="language" onChange={changeLanguage} />
										</label>
									</div>
								</div>
							</div>
						</ul>
					</ul>
				</nav>

				<nav className="navbar">
					<ul className="navbar__list">
						<li className="navbar__item"><Link smooth to="top" className="navbar__link">{t("header.home")}</Link></li>
						<li className="navbar__item"><Link smooth to="prices" className="navbar__link">{t("header.prices")}</Link></li>
						<li className="navbar__item navbar__logo"><img className="navbar__logo__image" src={logo} alt="" /></li>
						<li className="navbar__item"><Link smooth to="gallery" className="navbar__link">{t("header.gallery")}</Link></li>
						<li className="navbar__item"><Link smooth to="contact" className="navbar__link">{t("header.contact")}</Link></li>
					</ul>

					<ul className="navbar__socials">
						<li className="navbar__socials__item">
							<a className="navbar__socials__link navbar__socials__link--instagram" target="_blank" rel="noreferrer" href="https://www.instagram.com/toilettagecoconutetcie/">
								<i className="fa-brands fa-instagram"></i>
								<FontAwesomeIcon icon={faInstagram} />
							</a>
						</li>

						<li className="navbar__socials__item">
							<a className="navbar__socials__link navbar__socials__link--facebook" target="_blank" rel="noreferrer" href="https://www.facebook.com/Toilettage-Coconutcie-101100969493178">
								<FontAwesomeIcon icon={faFacebookF} />
							</a>
						</li>

						<div className="lang-select__wrapper">
							<div className="lang-select lang-select__dropdown">
								<div className="lang-select__button">
									{t("lang.select")} <FontAwesomeIcon icon={faCaretDown} />
								</div>

								<div className="lang-select__dropdown dropdown__content">
									<label>
										{t("lang.fr")}
										<input type="radio" value="fr" name="language" onChange={changeLanguage} />
									</label>

									<label>
										{t("lang.en")}
										<input type="radio" value="en" name="language" onChange={changeLanguage} />
									</label>
								</div>
							</div>
						</div>
					</ul>
				</nav>
			</header>
		</>
	)
}

export default Header;