import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
	const { t } = useTranslation();

	return (
		<footer className="footer">
			<p className="footer__text"><strong>{t("site.name")}</strong> © 2022</p>
			<p className="footer__rights">{t("footer.rights")}</p>
		</footer>
	)
}

export default Footer;