import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

function Homepage() {
	const { t } = useTranslation();

	return (
		<div className="homepage" id="homepage">
			<div className="homepage__container">
				<div className="homepage__content">
					<p className="homepage__title">{t("site.name")}</p>
					<p className="homepage__text">{t("homepage.text")}</p>

					<Link smooth to="contact" className="homepage__button">{t("homepage.button")}</Link>
				</div>
			</div>
		</div>
	)
}

export default Homepage;