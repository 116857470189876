import React, { Suspense } from 'react';
import './assets/css/global.css';
import Header from './components/Header';
import Homepage from './components/Homepage';
import Prices from './components/Prices';
import Contact from './components/Contact';
import Footer from './components/Footer';
const Gallery = React.lazy(() => import('./components/Gallery'));

function App() {
  return (
    <>
      <Header />

      <div className="container">
        <main>
          <Homepage />
          <Prices />
          <Suspense>
            <Gallery />
          </Suspense>
          <Contact />
        </main>
      </div>

      <Footer />
    </>
  );
}

export default App;
