import { useTranslation } from 'react-i18next';
import dog from '../assets/images/dog.jpg';
import cat from '../assets/images/cat.jpg';

function Prices() {
	const { t } = useTranslation();

	return (
		<div className="prices" id="prices">
			<div className="prices__container">
				<h2 className="prices__title">{t("prices.dog.title")}</h2>

				<div className="prices__card prices__card--dog">
					<div className="prices__image">
						<img className="prices__image--dog" src={dog} alt="" />
					</div>

					<div className="prices__content">
						<ul className="prices_details">
							<h3 className="prices_details__title">{t("prices.dog.inc.title")}</h3>
							<li className="prices_details__item">{t("prices.inc.1")}</li>
							<li className="prices_details__item">{t("prices.inc.2")}</li>
							<li className="prices_details__item">{t("prices.inc.3")}</li>
							<li className="prices_details__item">{t("prices.inc.4")}</li>
							<li className="prices_details__item">{t("prices.inc.5")}</li>
						</ul>

						<div className="prices__price">80$ / 180$</div>
						<p className="prices__note">{t("prices.dog.note")}</p>
					</div>
				</div>

				<h2 className="prices__title">{t("prices.cat.title")}</h2>

				<div className="prices__card prices__card--cat">
					<div className="prices__image">
						<img className="prices__image--cat" src={cat} alt="" />
					</div>

					<div className="prices__content">
						<ul className="prices_list">
							<li className="prices_list__item">
								<p className="prices_list__text">{t("prices.cat.1")}</p>

								<ul className="prices_details">
									<li className="prices_details__item">{t("prices.cat.inc.2")} **</li>
									<li className="prices_details__item">{t("prices.inc.1")}</li>
									<li className="prices_details__item">{t("prices.inc.5")}</li>
									<li className="prices_details__item">{t("prices.cat.inc.1")}</li>
								</ul>
							</li>
							<li className="prices_list__item">
								<p className="prices_list__text">{t("prices.cat.2")} **</p>

								<ul className="prices_details">
									<li className="prices_details__item">{t("prices.cat.inc.3")}</li>
									<li className="prices_details__item">{t("prices.inc.1")}</li>
									<li className="prices_details__item">{t("prices.inc.5")}</li>
									<li className="prices_details__item">{t("prices.cat.inc.5")}</li>
									<li className="prices_details__item">{t("prices.cat.inc.4")}</li>
								</ul>
							</li>
						</ul>

						<div className="prices__price">100$ / 200$</div>
						<p className="prices__note">** {t("prices.cat.note")}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Prices;