import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import emailjs from "@emailjs/browser";

function Contact() {
	const { t } = useTranslation();
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm('service_mtpcbtf', 'template_gf83pud', form.current, 'QcFgTBz3Fh8YUDpai')
			.then((result) => {
				alert(t("contact.alert.ok"))
			}, (error) => {
				alert(t("contact.alert.error"));
			});
	}

	return (
		<div className="contact" id="contact">
			<div className="shape_divider">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
					<path d="M649.97 0L550.03 0 599.91 54.12 649.97 0z" className="shape-fill"></path>
				</svg>
			</div>

			<div className="contact__container">
				<form className="contact_form" method="POST" autoComplete="off" ref={form} onSubmit={sendEmail}>
					<div className="contact_form__input">
						<label htmlFor="name">{t("contact.label.name")}</label>
						<input type="text" name="name" required />
					</div>

					<div className="contact_form__input">
						<label htmlFor="email">{t("contact.label.email")}</label>
						<input type="email" name="email" required />
					</div>

					<div className="contact_form__input">
						<label htmlFor="phone">{t("contact.label.phone")}</label>
						<input type="text" name="phone" required />
					</div>

					<div className="contact_form__input">
						<label htmlFor="address">{t("contact.label.address")}</label>
						<input type="text" name="address" required />
					</div>

					<div className="contact_form__input">
						<label htmlFor="message">{t("contact.label.message")}</label>
						<textarea name="message" rows="10" required />
					</div>

					<input type="submit" className="contact_form__submit" value={t("contact.submit.send")} />
				</form>

				<div className="contact__content">
					<h2 className="contact__title">{t("contact.title")}</h2>

					<p className="contact__text contact__text--main">{t("contact.text.1")}</p>
					<p className="contact__text contact__text--sub">{t("contact.text.2")}</p>

					<a className="contact__link contact__link--phone" href="tel:438 994-8140"><FontAwesomeIcon icon={faPhone} /> 438 994-8140</a>
					<a className="contact__link contact__link--instagram" target="_blank" rel="noreferrer" href="https://www.instagram.com/toilettagecoconutetcie/">
						<FontAwesomeIcon icon={faInstagram} /> Instagram
						</a>
					<a className="contact__link contact__link--facebook" target="_blank" rel="noreferrer" href="https://www.facebook.com/Toilettage-Coconutcie-101100969493178">
						<FontAwesomeIcon icon={faFacebookF} /> Facebook
						</a>
				</div>
			</div>
		</div>
	)
}

export default Contact;